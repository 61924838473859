<template>
  
  <div class="card card-custom card-stretch gutter-b">
  
    <div class="card-header border-0 py-5">
      <h3 class="card-title align-items-start flex-column"></h3>
      <div class="card-toolbar">
        <a
          href="#"
          class="btn btn-primary font-weight-bolder font-size-sm"
          @click="create_customer_clicked"
          ><i class="menu-icon flaticon2-plus" style="font-size: 1.0em;"></i>{{ $t('CUSTOMER.CREATE') }}</a
        >
      </div>
    </div>

    <div class="card-header border-0 py-5 m-1">
      <b-pagination
        class="ml-auto mb-0 align-middle"
        v-model="current_page"
        :total-rows="items.length"
        :per-page="per_page"
        aria-controls="customers-table"
        first-number
        last-number
      ></b-pagination>
    </div>
    

    <div class="card-body pt-0 table-responsive">
      <b-table
        id="customers-table"
        :fields="headers"
        :items="items"
        :per-page="per_page"
        :current-page="current_page"
        :sort-desc="true"
        sort-by="created_at"
        head-variant="light"
        sticky-header
        class="mh-100 table-striped"
      >

        <template #cell(actions)="row">
          <div class="justify-content-end d-flex">
            
            <a href="#" class="btn btn-icon btn-light btn-sm mr-3" @click.prevent="select_customer_clicked(row.item.id)">
              <span class="svg-icon svg-icon-md svg-icon-primary" >
                <inline-svg src="/assets/svg/Write.svg"></inline-svg>
              </span>
            </a>

            <!--<a href="#" class="btn btn-icon btn-light btn-sm" @click.prevent="delete_customer_clicked(row.item.id)">
              <span class="svg-icon svg-icon-md svg-icon-primary">
                <inline-svg src="/assets/svg/Trash.svg"></inline-svg>
              </span>
            </a>-->
          </div>
        </template>
      </b-table>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_memlist_checkbox.scss";
@import "@/assets/sass/components/forms/_memlist_table.scss";

</style>
<script>


import { get_domain_url, downloadWithAxios } from '@/core/services/fileDownload';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'CustomersTable',
  props: ['items', 'currentCompanyId'],
  emits: ['select_customer_clicked', 'delete_customer_clicked'],
  mixins: [ toasts ],
  components: {  },
  methods: {
    create_customer_clicked() {
      this.$emit('create_customer_clicked');
    },

    select_customer_clicked(id) {
      this.$emit('select_customer_clicked', id);
    },

    delete_customer_clicked(id) {
      this.$emit('delete_customer_clicked', id);
    },

  },
  data() {
    return {
      current_page: 1,
      per_page: 100,
      options: [100, 150, 200],
      headers: [
        {
          key: 'name',
          label: this.$t('CUSTOMER.NAME'),
          sortable: true,
          thClass: '',
          tdClass: 'td-contain'
        },
        {
          key: 'created_at',
          label: this.$t('CUSTOMER.CREATED_AT'),
          sortable: true,
          thClass: 'pl-7',
          tdClass: 'pl-7'
        },
        {
          key: 'actions',
          label: '',
          thClass: 'w-150px text-right pr-22'
        }
      ],
      list: []
    };
  }
};
</script>
