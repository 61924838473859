<template>
  <div class="customer-editor">
    <form @submit.prevent="on_save_clicked">
      <!-- Basic Information -->
      <div class="form-section">
        <h4>{{ $t('CUSTOMER.BASIC_INFORMATION') }}</h4>
        <memlist-text-input
          id="id"
          name="id"
          :title="$t('CUSTOMER.ID')"
          layout="vertical"
          :disabled="true"
          v-model="local_item.id"
        />

        <memlist-text-input
          id="name"
          name="name"
          :title="$t('CUSTOMER.NAME')"
          layout="vertical"
          v-model="local_item.name"
          :required="true"
        />

        <memlist-text-input
          id="root_company_id"
          name="root_company_id"
          :title="$t('CUSTOMER.ROOT_COMPANY_ID')"
          :disabled="true"
          layout="vertical"
          v-model="local_item.root_company_id"
        />

        <div class="logo-upload-section d-sm-flex align-items-center mb-4 mt-8">
          <!-- Logo Preview -->
          <div class="logo-preview">
            <img :src="logoUrl" alt="Logo Preview" />
          </div>
          <!-- Upload Button -->
          <div class="upload-button ml-3">
            <a
              href="#"
              class="btn btn-primary font-weight-bolder font-size-sm my-2"
              @click.prevent="select_file"
            >
              <i class="menu-icon flaticon2-plus" style="font-size: 1.0em;"></i>
              {{$t('FILE.UPLOAD_FILE')}}
            </a>
            <input hidden type="file" id="file" ref="file" @change="handle_file_upload" />
          </div>
        </div>
        
      </div>

      <!-- Contact Information -->
      <div class="form-section">
        <h4>{{ $t('CUSTOMER.CONTACT_INFORMATION') }}</h4>
        <memlist-text-input
          id="c_name"
          name="c_name"
          :title="$t('CUSTOMER.CONTACT_NAME')"
          layout="vertical"
          v-model="local_item.c_name"
        />
        <memlist-text-input
          id="admin_email"
          name="admin_email"
          :title="$t('CUSTOMER.ADMIN_EMAIL')"
          layout="vertical"
          v-model="local_item.admin_email"
        />
        <memlist-text-input
          id="admin_phone"
          name="admin_phone"
          :title="$t('CUSTOMER.ADMIN_PHONE')"
          layout="vertical"
          v-model="local_item.admin_phone"
        />
      </div>

      <!-- Error Popup -->
      <ErrorPopup :error="error_popup_message" />

      <!-- Action Buttons -->
      <RightModalSaveAndCloseButtons
        :text="$t('COMMON.SAVE')"
        :spin="true"
        @clicked="on_save_clicked"
        @close="$emit('cancel')"
        ref="saveButton"
      />
    </form>
  </div>
</template>

<style lang="scss" scoped>


.logo-upload-section {
  display: flex;
  align-items: center;
}

.logo-preview img {
  width: 100px; /* Adjust as needed */
  height: auto;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.upload-button {
  margin-left: 20px;
}

.customer-editor {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

form {
  display: flex;
  flex-direction: column;
}

.form-section {
  margin-bottom: 30px;
}

.form-section h4 {
  margin-bottom: 15px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 5px;
  grid-column: 1 / -1;
}

.form-group {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}

.form-group label {
  margin-right: 10px;
}

@media (min-width: 768px) {
  .form-section {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 20px;
  }
}

@media (max-width: 767px) {
  .form-section {
    display: block;
  }
}
</style>

<script>
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { mapGetters } from 'vuex';
import axios from 'axios';

import RightModalSaveAndCloseButtons from '@/view/components/buttons/RightModalSaveAndCloseButtons.vue';
import ErrorPopup from '@/view/components/ErrorPopup.vue';
import { upload_file } from '@/core/services/fileUpload';

export default {
  name: 'CustomerEditor',
  props: ['item'],
  emits: ['updated', 'cancel'],
  mixins: [toasts],
  components: {
    RightModalSaveAndCloseButtons,
    ErrorPopup,
  },
  data() {
    return {
      error_popup_message: null,
      local_item: {},
      orgTypeOptions: [
        { value: 'NONE', text: 'None' },
        // Add other options based on your ORG_TYPE enum
      ],
      periodOptions: [
        { value: 'NONE', text: 'None' },
        // Add other options based on your PERIOD enum
      ],
      orgStructureOptions: [
        { value: 'NONE', text: 'None' },
        // Add other options based on your ORG_STRUCTURE enum
      ],
      paymentLevelOptions: [
        { value: 'LEGACY', text: 'Legacy' },
        // Add other options based on your CUSTOMER_LEVEL enum
      ],
      statusOptions: [
        { value: 'LEGACY', text: 'Legacy' },
        // Add other options based on your CUSTOMER_STATUS enum
      ],
    };
  },
  computed: {
    logoUrl() {
      if (this.local_item.logo_url) {
        return this.local_item.logo_url;
      } else {
        return require('@/assets/images/placeholder.png');
      }
    },
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'companies']),
  },
  watch: {
    item: {
      deep: true,
      handler(val) {
        this.local_item = { ...this.item };
      },
    },
  },
  mounted() {
    if (this.item) {
      this.local_item = { ...this.item };
    }
  },
  methods: {
    async on_save_clicked() {
      if (this.local_item.id) {
        const res = await axios.put(`/customer/${this.local_item.id}`, this.local_item);

        if (res.status === 204) {
          this.$emit('updated', this.local_item);
        }
      }
      else {
        const res = await axios.post(`/customer`, this.local_item);

        if (res.status === 201) {
          this.$emit('created', res.data);
        }
      }

      this.$refs['saveButton'].stop();
    },

    async handle_file_upload() {
      const file = this.$refs.file.files[0];

      if (file.size > (50000000)) {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.FILES.MUST_BE_LESS'));
        return;
      }

      try {
        const newfile = await upload_file(file, { file_type: 'PUBLIC_IMAGE', is_public: true });

        this.local_item.logo_url = newfile.public_path;

        if (this.local_item.id) {
          this.on_save_clicked();
        }

        this.toastr('success', this.$t('COMMON.OK'), this.$t('FILE.UPLOADED'));
      }
      catch (err) {
        console.error('handle_file_upload', err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('FILE.UNABLE_UPLOAD'));
      }
      
    },

    select_file() {
      this.$refs.file.click();
    },
    
  },
};
</script>
