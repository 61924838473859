<template>
  <div id="page-customers">
    <h5 class="m-4">{{ $t('CUSTOMER.CUSTOMERS') }}</h5>

    <Confirm
      :title="$t('CUSTOMER.DELETE_CONFIRM_TITLE')"
      :message="$t('CUSTOMER.DELETE_CONFIRM_MESSAGE')"
      ref="delete-customer-confirm"
      type="success"
      @confirm="delete_customer_confirmed"
    />

    <CustomerEditorModal
      :item="selected_item"
      ref="customer-editor-modal"
      @updated="customer_updated"
      @created="customer_created"
    />

    <CustomersTable
      :items="items"
      @delete_customer_clicked="delete_customer_clicked"
      @select_customer_clicked="select_customer_clicked"
      @create_customer_clicked="create_customer_clicked"
    />

  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_memlist_checkbox.scss";
</style>

<script>

import axios from 'axios';
import { mapGetters } from 'vuex';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import CustomersTable from '@/view/pages/ml/customers/CustomersTable.vue';
import CustomerEditorModal from '@/view/pages/ml/customers/CustomerEditorModal.vue';
import Confirm from '@/view/components/Confirm.vue';


export default {
  name: 'CustomersPage',
  mixins: [ toasts ],
  components: {
    Confirm,
    CustomersTable,
    CustomerEditorModal,
  },
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'periods'])
  },
  mounted() {
    this.load_customers();
  },
  data() {
    return {
      selected_item: {},
      items: []
    };
  },
  watch: {
    currentCompanyId(newValue, oldvalue) {
      if (newValue) {
        
      }
    },
    currentPeriodId(newValue, oldValue) {
      if (this.currentCompanyId) {

      }
    }
  },
  methods: {

    create_customer_clicked() {
      
      this.$nextTick(()=>{
        this.$refs['customer-editor-modal'].show();
      });
    },

    customer_updated(item) {
      const index = this.items.findIndex(i => i.id === item.id);

      if (index > -1) {
        this.items[index] = item;
      }

      this.items = [...this.items];
    },

    customer_created(item) {
      this.items.push(item);
    },

    delete_customer_clicked(id) {
      this.selected_item.id = id;

      this.$refs['delete-customer-confirm'].show();
    },

    async select_customer_clicked(id) {
      this.selected_item = this.items.find(item => item.id === id);

      const item = await axios.get(`/customer/${id}`);

      this.selected_item = item.data;

      this.$nextTick(()=>{
        this.$refs['customer-editor-modal'].show();
      });
    },

    async delete_customer_confirmed() {
      
      try {
        const response = await axios.delete(`/customer/${this.selected_item.id}`);

        if (response.status === 204) {
          this.toastr('success', this.$t('COMMON.OK') , this.$t('CUSTOMER.DELETED'));

          this.items = this.items.filter(item => item.id !== this.selected_item.id);

          return;
        }
      }
      catch (err) {
        console.error('delete_customer_confirmed error', err);
      }

      this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('CUSTOMER.UNABLE_DELETE'));

    },

    async load_customers() {
      
      try {
        const response = await axios.get(`/customer`);

        if (response.status === 200) {
          this.items = response.data;

          return;
        }
      }
      catch (err) {
        console.error('load_customers error', err);
      }

      this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('CUSTOMER.UNABLE_LIST'));

    },
  }
};
</script>
